import { type AutocompleteItem as AutoCompleteProps } from '.';
import { useClickAway, useHover } from '@uidotdev/usehooks';
import Input, { type InputProps } from '@atoms/new/input';
import { Listbox, ListboxItem } from '@nextui-org/listbox';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Tooltip from '@atoms/new/tooltip';

interface InputWithAutocompleteProps {
  query: string;
  onQueryChange: (value: string) => void;
  showAutoComplete: boolean;
  isLoading: boolean;
  autocomplete: any[];
  inputProps: InputProps;
  setShowAutoComplete: (value: boolean) => void;
  onAutocompleteClick: (value: AutoCompleteProps) => void;
  onDeleteAutoComplete: (value: string) => void;
  isOnBoarding?: boolean;
}

export const InputWithAutocomplete = ({
  query,
  onQueryChange,
  showAutoComplete,
  autocomplete,
  onAutocompleteClick,
  setShowAutoComplete,
  inputProps,
  onDeleteAutoComplete,
  isOnBoarding = false
}: InputWithAutocompleteProps) => {
  const autoCompleteRef = useClickAway(() => {
    setShowAutoComplete(false);
  });

  return (
    <div className="relative">
      <Input
        value={query}
        {...inputProps}
        variant="bordered"
        onChange={(e) => onQueryChange(e.target.value)}
      />

      {showAutoComplete && autocomplete.length !== 0 && (
        <Listbox
          ref={autoCompleteRef as any}
          disabledKeys={['ideas-for-you']}
          className={
            'absolute z-50 bg-[#ffffff] overflow-auto rounded-b-md shadow-xl border py-4 px-2 my-0 border-t-0 border-grey-200'
          }
        >
          {
            [isOnBoarding && <ListboxItem title="Ideas for you" key="ideas-for-you" />,
              ...autocomplete.map((item) => (
                <ListboxItem
                  key={item.id}
                  className="cursor-pointer py-2 data-[hover=true]:bg-grey-100 rounded-md"
                  onClick={() => onAutocompleteClick(item)}
                >
                  <AutocompleteItem
                    item={item}
                    onDelete={onDeleteAutoComplete}
                  />
                </ListboxItem>
              ))
            ].filter(item => item !== false)
          }
        </Listbox>
      )}
    </div>
  );
};

interface AutocompleteItemProps {
  item: AutoCompleteProps;
  onDelete: (value: string) => void;
}

const AutocompleteItem = ({ item, onDelete }: AutocompleteItemProps) => {
  const [hoverRef, hovering] = useHover();

  return (
    <div ref={hoverRef} className="flex items-center justify-between">
      <p className="text-grey-900 font-semibold">{item.topic}</p>
      {!item.isDefault && hovering && (
        <Tooltip content="Remove suggestion">
          <div className='cursor-pointer' onClick={e => {
            e.stopPropagation();
            onDelete(item.topic);
          }}>
            <CloseRoundedIcon fontSize="small" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
