import { type InteractionColors, type InteractionType } from '@components/Presentation/types';
import { type ThemeConfig } from '@components/themes/store/ThemeStore';

function extractRgbValuesFromGradient(gradient: string): number[][] {
  // Regular expression to match rgb and rgba colors
  const regex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/g;

  // Array to store the RGB values
  const rgbValues: number[][] = [];

  let match;

  // Loop through all matches in the gradient string
  while ((match = regex.exec(gradient)) !== null) {
    // Extract the RGB values (r, g, b) and optional alpha value
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);

    // If alpha is provided, it will be available in match[4] but we don't need it for this task
    rgbValues.push([r, g, b]);
  }

  return rgbValues;
}

export function computeLuminance(color: string, threshold: number = 0.5) {
  let backgroundColor = color.toLowerCase();
  let r, g, b;
  if (backgroundColor?.startsWith('#')) {
  // Remove the hash symbol if present
    backgroundColor = backgroundColor.slice(1);

    // Convert short form (#fff) to long form (#ffffff)
    if (backgroundColor.length === 3) {
      backgroundColor = backgroundColor.split('').map(char => char + char).join('');
    }

    // Extract RGB values
    r = parseInt(backgroundColor.slice(0, 2), 16);
    g = parseInt(backgroundColor.slice(2, 4), 16);
    b = parseInt(backgroundColor.slice(4, 6), 16);
  } else if (['rgb', 'linear-gradient', 'radial-gradient'].some(prefix => backgroundColor.startsWith(prefix))) {
    const rgbValues = extractRgbValuesFromGradient(backgroundColor)[0];
    r = rgbValues[0];
    g = rgbValues[1];
    b = rgbValues[2];
  } else {
    console.error('Invalid color format', color);
    return { isBgLight: false };
  }

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return true or false based on luminance
  return { isBgLight: luminance > threshold };
};

export function getInteractionBgColorKey(interactionType: InteractionType): keyof InteractionColors | null {
  switch (interactionType) {
    case 'pollWithOptions': return 'pollBgColor';
    case 'freeForm': return 'pollBgColor';
    case 'wordCloud': return 'wcBgColor';
    case 'wheel': return 'choiceCircleBgColor';
    case 'emojisEverywhere': return 'emojisEverywhereBgColor';
    case 'talkingTiles': return 'talkingTilesBgColor';
    case 'transientThought': return 'ttBgColor';
    case 'newWheel': return 'winningWheelBgColor';
    case 'linkLibrary': return 'linkLibraryBgColor';
    case 'Quick Questions': return 'quickQuestionBgColor';
    case 'textTrack': return 'textTrackBgColor';
    case 'ratingPolls': return 'ratingPollsBgColor';
    case 'fantasticFans': return 'ffBgColor';
    case 'map': return 'mapBgColor';
    case 'multipleChoice': return 'multipleChoiceBgColor';
    default:
      return null;
  }
};

export type InteractionKeys = Exclude<InteractionType, 'PDF Slide'>

type InteractionColorMap = {
  bg: Record<InteractionKeys, Partial<keyof InteractionColors>>
  heading: Record<InteractionKeys, Partial<keyof InteractionColors>>
}

export const interactionColorMap: InteractionColorMap = {
  bg: {
    emojisEverywhere: 'emojisEverywhereBgColor',
    pollWithOptions: 'pollBgColor',
    freeForm: 'pollBgColor',
    wordCloud: 'wcBgColor',
    wheel: 'choiceCircleBgColor',
    talkingTiles: 'talkingTilesBgColor',
    transientThought: 'ttBgColor',
    newWheel: 'winningWheelBgColor',
    linkLibrary: 'linkLibraryBgColor',
    'Quick Questions': 'quickQuestionBgColor',
    textTrack: 'textTrackBgColor',
    fantasticFans: 'ffBgColor',
    ratingPolls: 'ratingPollsBgColor',
    map: 'mapBgColor',
    multipleChoice: 'multipleChoiceBgColor'
  },
  heading: {
    pollWithOptions: 'pollWithOptionsHeadingColor',
    freeForm: 'pollHeadingColor',
    wordCloud: 'wcHeadingColor',
    wheel: 'choiceCircleHeadingColor',
    talkingTiles: 'talkingTilesHeadingColor',
    transientThought: 'ttHeadingColor',
    newWheel: 'winningWheelHeadingColor',
    linkLibrary: 'linkLibraryHeadingColor',
    'Quick Questions': 'quickQuestionHeadingColor',
    textTrack: 'textTrackHeadingColor',
    fantasticFans: 'ffHeadingColor',
    ratingPolls: 'ratingPollsHeadingColor',
    map: 'mapHeadingColor',
    emojisEverywhere: 'emojisEverywhereHeadingColor',
    multipleChoice: 'multipleChoiceHeadingColor'
  }
};

export function compareAspectRatioWith16x9or4x3({ width, height }: { width: number, height: number }, layoutMode: 'rectangle' | 'square') {
  if (layoutMode === 'square') return width > height;
  const aspectRatio = width / height;
  return aspectRatio - (16 / 9) > 0; // true for ultrawide \ false for horizonally wide screens
};

export const interactionImgKeyMap: Record<
  Exclude<InteractionType, 'PDF Slide'>,
  keyof ThemeConfig['images']
> = {
  pollWithOptions: 'pollWithOptionsImageSrc',
  freeForm: 'pollImageSrc',
  wordCloud: 'wcImageSrc',
  newWheel: 'winningWheelImageSrc',
  map: 'mapImageSrc',
  linkLibrary: 'linkLibraryImageSrc',
  transientThought: 'ttImageSrc',
  emojisEverywhere: 'emojisEverywhereImageSrc',
  talkingTiles: 'talkingTilesImageSrc',
  'Quick Questions': 'quickQuestionImageSrc',
  textTrack: 'textTrackImageSrc',
  ratingPolls: 'ratingPollsImageSrc',
  fantasticFans: 'ffImageSrc',
  wheel: 'choiceCircleImageSrc',
  multipleChoice: 'multipleChoiceImageSrc'
};
