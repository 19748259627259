/* eslint-disable camelcase */
import {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import moment from 'moment';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { StreamDataContext } from '@components/context/StreamContext';
import {
  postIpaUserEvent,
  wakeUpLambdaFunctions,
  connectToYoutubeStream,
  getLinkQuestions,
  stopEngagement,
} from '@services/youtube-platform.service';
import ZoomSandbox from '@components/Sandbox/Zoom';
import Image from '@atoms/Image';
import { useRouter } from 'next/router';
import Button from '@atoms/new/button';
import IpaSandbox from '@components/Sandbox/Ipa';
import MeetSandbox from '@components/Sandbox/Meet';
import TwitchSandbox from '@components/Sandbox/Twitch';
import YoutubeSandbox from '@components/Sandbox/Youtube';
import { pushDataLayerForEvent } from '@lib/gtag';
import useDeviceOrientation from '@lib/hooks/useDeviceOrientation';
import RequestLandscapeModal from '@components/Sandbox/RequestLandscapeModal';
import NoticeBubble from '@atoms/NoticeBubble';
import TeamsSandbox from './Teams';
import { nonInteractiveFeatures } from '@components/Layout/helper';
import { PlatformsTabs } from './PlatformTabGroup';
import AirmeetSandbox from './Airmeet';
import StreamyardSandbox from './Streamyard';
import RestreamSandbox from './Restream';
import LinkedInSandbox from './linkedin';
import KickSandbox from './kick';
import { useGeneratedResponses } from './useGeneratedResponses';

export default function Sandbox({
  platformId,
  platform = 'zoom',
  is_ros_preview = false,
  is_ai_generated = false,
  templateId = '54e37f08-2ae7-4a32-ac8c-ba60fe5d5d0b'
}) {
  const router = useRouter();
  const { isMobile, orientation } = useDeviceOrientation();

  const [linkQuestions, setLinkQuestions] = useState(null);
  const [currentRosFeature, setCurrentRosFeature] = useState();
  const [next] = useState(linkQuestions?.length === 0);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentFeatureId] = useState(null);

  const [crudLoading, setCrudLoading] = useState(true);
  const { teamContext } = useContext(TeamsDataContext);
  const { setIntervalId } = useContext(StreamDataContext);
  const [currentRosResult] = useState([]);
  const [stream, setStream] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(isMobile);

  useGeneratedResponses({
    feature: currentRosFeature,
    streamId: stream?.id
  });

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const featurePrevState = () => {
    if (currentIndex > 0) {
      pushDataLayerForEvent('sandbox_previous_arrow_pressed', { streamId: stream.id }, false);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const featureNextState = () => {
    if (currentIndex < linkQuestions.length - 1) {
      pushDataLayerForEvent('sandbox_next_arrow_pressed', { streamId: stream.id }, false);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const stopFeature = async (id, type) => {
    if (nonInteractiveFeatures.includes(type)) {
      return;
    }

    const sessionId = localStorage.getItem('sessionId');
    pushDataLayerForEvent(`sandbox_stop_${type}_interaction`, { streamId: stream.id }, false);

    stopEngagement(
      stream.id,
      type,
      {
        sessionId,
        engagementId: id,
        platformType: stream.platform_type
      }
    );
  };

  useEffect(() => {
    if (!currentIndex && !linkQuestions) return;
    stopFeature(currentFeatureId, currentRosFeature?.type);
    setCurrentRosFeature(linkQuestions[currentIndex]);
    pushDataLayerForEvent(`sandbox_start_${currentRosFeature.type}_interaction`, { streamId: stream.id }, false);
  }, [currentIndex]);

  const connectToStream = async (event) => {
    await wakeUpLambdaFunctions();
    const newIntervalId = setInterval(async () => {
      await wakeUpLambdaFunctions();
    }, 15 * 60 * 1000);
    setIntervalId(newIntervalId);
    const scheduledStartTime = event.event_time;
    const engageType = new Date().getTime() > new Date(scheduledStartTime).getTime()
      ? 'active'
      : 'scheduled';

    const res = await connectToYoutubeStream({
      liveChatId: event.id,
      platformId: event.platform_id,
      statusFlag: true,
      engageType,
      platformType: event.livestream.platform_type,
      entityId: event.id,
      title: event.title,
      userId: event.livestream.user_id,
      orgStartTime: event.event_time,
      totalTimeWithText: '12 PM'
    });

    setStream(res.entity);
    setCurrentIndex(res.entity.ros_index || 0);

    const id = res.entity.template_id;
    const tempQuestions = await getLinkQuestions(id);
    setLinkQuestions(tempQuestions.entity);

    setCurrentRosFeature(tempQuestions.entity[res.entity.ros_index || 0]);

    localStorage.setItem('streamId', res.entity.id);
    localStorage.setItem('sessionId', res.entity.sessionId);
    window.parent.postMessage('ready', '*');
  };

  const connectSandbox = async () => {
    setCrudLoading(true);
    const date = new Date();
    const event_time = date.toISOString();

    const res = await postIpaUserEvent({
      platform_id: platformId,
      title: 'sandbox',
      event_time,
      user_id: teamContext?.oid,
      template_id: templateId,
      event_type: 'ipa',
      is_sandbox: true,
      is_ros_preview,
      is_ai_generated,
      totalTimeWithText: moment(event_time).format('hh:mm A')
    });

    connectToStream(res.entity);

    setCrudLoading(false);
  };

  useEffect(() => {
    if (!teamContext?.oid) return;

    connectSandbox();
  }, [teamContext]);

  const handleKeyStrokes = (e) => {
    const { key } = e;

    if (key.match(/ArrowRight/)) {
      e.preventDefault();
      featureNextState();
    }

    if (key.match(/ArrowLeft/)) {
      e.preventDefault();
      featurePrevState();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyStrokes);
    return () => {
      window.removeEventListener('keydown', handleKeyStrokes);
    };
  }, [currentIndex, currentRosFeature]);

  const [showOrientationModal, setShowOrientationModal] = useState(false);

  useEffect(() => {
    setNotificationVisible(isMobile);

    if (isMobile && orientation === 'Portrait') {
      setShowOrientationModal(true);
      return;
    }

    setShowOrientationModal(false);
  }, [isMobile, orientation]);

  const getPlatformUI = () => {
    if (platform === 'kick') {
      return <KickSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'linkedin') {
      return <LinkedInSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'restream') {
      return <RestreamSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'streamyard') {
      return <StreamyardSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'airmeet') {
      return <AirmeetSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'teams') {
      return <TeamsSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }
    if (platform === 'ipa') {
      return <IpaSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'meet') {
      return <MeetSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'twitch') {
      return <TwitchSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    if (platform === 'youtube') {
      return <YoutubeSandbox
        stream={stream}
        currentIndex={currentIndex}
        featurePrevState={featurePrevState}
        featureNextState={featureNextState}
        linkQuestions={linkQuestions || []}
        nextRef={nextRef}
        prevRef={prevRef}
        next={next}
        feature={currentRosFeature}
        stopFeature={stopFeature}
        currentRosResult={currentRosResult}
      />;
    }

    return <ZoomSandbox
      stream={stream}
      currentIndex={currentIndex}
      featurePrevState={featurePrevState}
      featureNextState={featureNextState}
      linkQuestions={linkQuestions || []}
      nextRef={nextRef}
      prevRef={prevRef}
      next={next}
      feature={currentRosFeature}
      stopFeature={stopFeature}
      currentRosResult={currentRosResult}
    />;
  };

  const setPlatform = (newPlatform) => {
    pushDataLayerForEvent('sandbox_platform_changed', { platform: newPlatform });
    const [pathWithoutQueryParams] = router.asPath.split('?');
    router.replace(`${pathWithoutQueryParams}?platform=${newPlatform}`);
  };

  if (crudLoading) return null;

  const streamTitle = stream?.title.replace(/-\d+$/, '');

  return (
    <div className='relative h-full bg-white'>
      <div className={`flex items-center z-50 py-3 justify-between px-4 ${!is_ros_preview ? 'border-b border-grey-200' : ''}`}>
        {is_ros_preview ? (
          <p className='font-semibold text-xl inline-block flex-1 truncate pr-2 text-grey-800'>
            <span className='text-grey-900'>Preview:&nbsp;</span>{streamTitle}
          </p>
        ) : (
          <a href='https://streamalive.com' target='_blank'>
            <Image tabIndex={2} width={30} height={30}
              src='/images/logos/logo_tag_black.svg'
              className=" lg:w-47.5 md:w-40 w-24 md:block cursor-pointer" alt="logo icon" />
          </a>
        )}

        <PlatformsTabs
          platform={platform}
          selectPlatform={setPlatform}
        />

        {is_ros_preview ? <div className='flex-1' /> : (
          <Button
            onClick={() => window.open(process.env.NEXT_PUBLIC_REGISTER_ROUTE, '_blank')}
          >
            Sign up, it’s free
          </Button>
        )}
      </div>
      <div>
        <div
          className='px-4 pt-4 max-w-[1280px] min-w-[480px] mx-auto'>
          {getPlatformUI()}
        </div>
        <p className='text-center text-grey-700 text-sm font-normal mt-4'>This live presentation lets you interact with both screens to see how it works for the presenter and your audience in real-time.</p>
      </div>

      <RequestLandscapeModal visible={showOrientationModal} />
      <NoticeBubble
        visible={notificationVisible}
        onClose={() => setNotificationVisible(false)}
        message="This demo is best seen in a larger screen."
      />
    </div>
  );
}
