import React, {
  useEffect, useState, useContext,
  forwardRef
} from 'react';

import { doc, onSnapshot } from 'firebase/firestore';
import db from '@services/firebase-service';
import { logToCloudWatch } from '@services/logger.service';
import { stopEngagement } from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';

import MultipleChoiceOptions from './MultipleChoice/MultipleChoiceOptions';
import { handleEngagement } from '@services/interactions-service';
import Image from 'next/image';

const MultipleChoiceAnalytics = ({ analyticsData }) => {
  const relativeMax = analyticsData?.data ? Math.max(...analyticsData?.data?.map((option) => option.contribution ? option.contribution : 0)) : 100;
  const bgColor = analyticsData?.color?.multipleChoiceBgColor || '#111111';
  let bgImg = analyticsData.settings?.multipleChoiceImageSrc;
  let bgImgOpacity = analyticsData.settings?.multipleChoiceImageOpacity / 100;
  return (
    <div
      style={{ background: bgColor }}
      className={'h-full overflow-hidden w-full flex bg-black justify-evenly flex-col min-h-96 relative '}>
      {bgImg && <Image
        style={{ opacity: bgImgOpacity }}
        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImg}`}
        className=' object-cover rounded-lg'
        fill
        alt='background image'
      />}
      {analyticsData?.data?.map((option, index) => (
        <MultipleChoiceOptions
          isLiveResponsesVisible={false}
          option={option}
          settings={analyticsData.settings}
          index={index}
          relativeMax={relativeMax}
          showAnswer={true}
          totalOptionCount={analyticsData?.data.length}
        />
      ))}
    </div>
  );
};

const MultipleChoice = forwardRef(({
  streamId, platformType, currentRosFeature, currentRosResult, interactionType, setCurrentFeatureId, startInteraction,
  setShowRosModel,
  moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, showAnswerMap = {}, isAnalytics, analyticsData, setStartTime
}, ref) => {
  if (isAnalytics) return <MultipleChoiceAnalytics analyticsData={analyticsData} />;
  const {

    firestoreUnsub, setFirestoreUnsub, setFeatureId
  } = useContext(StreamDataContext);
  const { color } = currentRosFeature;
  const { isResult, isRos } = interactionType;
  const [multipleChoiceId, setMultipleChoiceId] = useState('');

  const sessionId = localStorage.getItem('sessionId');
  const [interactionResponse, setInteractionResponse] = useState(currentRosFeature.options);
  const [currentSettings, setCurrentSettings] = useState(currentRosFeature.setting || currentRosFeature.settings);
  const [previousData, setPreviousData] = useState([]);
  const stopMultipleChoice = async () => {
    if (multipleChoiceId) {
      await stopEngagement(streamId, 'multipleChoice', { engagementId: multipleChoiceId, platformType, sessionId });
      setMultipleChoiceId(null);
      setFeatureId(null);
      if (firestoreUnsub.unSub) {
        firestoreUnsub.unSub();
      }
    }
  };

  const setupMultipleChoice = ({
    id, current, prevData
  }) => {
    setInteractionResponse(current);
    setCurrentFeatureId(id);
    setMultipleChoiceId(id);
    setPreviousData(prevData);
    setFeatureId(id);
  };

  const handleMultipleChoice = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });
    if (response.status && response.entity) {
      const interactionDetails = {
        id: response.entity.engagementId,
        current: currentRosFeature.options,
        type: 'multipleChoice',
        prevData: [],
      };
      setupMultipleChoice(interactionDetails);
      setCurrentSettings(currentRosFeature.setting || currentRosFeature.settings);
      updateFSMeta({ activeInteraction: interactionDetails });
    }
  };

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleMultipleChoice({ interactionId: currentRosResult[0].id });
    } else {
      handleMultipleChoice({ interactionId: null });
    }
    if (activeInteractionRef.current) {
      updateRatingPolls();
    }
    setStartTime(Date.now());
  };

  const handleStopInteraction = () => {
    stopMultipleChoice();
    setMultipleChoiceId(null);
    if (currentRosResult && currentRosResult[0]?.options) {
      setInteractionResponse(currentRosResult[0]?.options);
    }
  };

  const manageMultipleChoice = () => {
    if (!isResult && startInteraction) {
      handleStartInteraction();
    } else if (multipleChoiceId) {
      handleStopInteraction();
    } else {
      if (currentRosResult && currentRosResult[0]?.options) {
        setInteractionResponse(currentRosResult[0]?.options);
      }
    }
  };

  useEffect(() => {
    setShowRosModel(false);
    manageMultipleChoice();
  }, [currentRosFeature.id, startInteraction]);

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  useEffect(() => {
    if (multipleChoiceId) {
      const logData = {
        streamId,
        interactionType: 'multipleChoice',
        interactionId: multipleChoiceId
      };

      setFirestoreUnsub({
        unSub: onSnapshot(doc(db, 'streams', streamId, 'multipleChoice', multipleChoiceId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            let { data } = document.data();
            if (data) {
              setInteractionResponse(data);
              setPreviousData(data);
              setShowNotification(false);
            }
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [multipleChoiceId]);
  const isShowAnswerVisible = showAnswerMap[currentRosFeature.id] || false;

  const data = interactionResponse || [];

  const relativeMax = Math.max(...data?.map((option) => option.contribution ? parseInt(option.contribution) : 0));

  const showResponses = currentSettings?.showResponses !== false || !startInteraction;

  return (
    <>
      <div className={'h-full overflow-hidden w-full flex justify-evenly flex-col  transition-transform ease-in-out duration-1000'}>

        {data.map((option, index) => (<MultipleChoiceOptions showResponses={showResponses} color={color} option = {option} settings = {currentSettings} index={index} relativeMax={relativeMax} showAnswer={isShowAnswerVisible} totalOptionCount = {data?.length}/>))}
      </div >
    </>
  );
});

export default MultipleChoice;
