import { useContext } from 'react';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { type PurchasePlanSettings } from '@components/PricingPlans';

export const usePurchasePlan = () => {
  const { currentTeam } = useContext(TeamsDataContext);
  const { name = '', settings = {} } = currentTeam?.billing_account?.purchase_plan ?? {};

  return {
    name: name as string,
    settings: settings as PurchasePlanSettings
  };
};
