const OptionOrderType = ({ index, type = 'number', prefix = '', className = '', style }: {
  index: number;
  type?: 'number' | 'letter';
  prefix?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const optionLabel: any = type === 'number' ? index + 1 : String.fromCharCode(65 + index);
  const label = prefix ? `${prefix}${optionLabel}` : optionLabel;

  const defaultClassName = 'min-w-9 min-h-9  border border-grey-400  bg-grey-200  grid place-items-center rounded-md overflow-hidden';
  return (
    <div
      style={style}
      className={ className || defaultClassName}>
      <span className="truncate px-3 font-Inter text-sm ">{label}</span>
    </div>
  );
};

export default OptionOrderType;
