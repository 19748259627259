import { useEffect, useRef } from 'react';
import {
  addDoc,
  collection,
  onSnapshot
} from 'firebase/firestore';
import db from '@services/firebase-service';
import { type InteractionType } from '@components/Presentation/types';
import { logsToAwsCloudWatch } from '@services/logger.service';
import { generateResponse } from '@services/generative-ai';

interface UseGeneratedResponsesProps {
  feature: {
    title: string;
    type: string;
    options: {
      option_number: string;
    }[];
    setting: {
      prefix: string;
    };
  };
  streamId: string;
}
interface OnCommentProps {
  message: string;
  user: string;
  interaction: InteractionType;
  streamId: string;
}
const onComment = async ({ message, user, interaction, streamId }: OnCommentProps) => {
  if (!message || !user || !interaction || !streamId) return;

  const rawMessagesRef = collection(db, 'streams', streamId, 'rawMessages');

  const document = {
    upvotes: 0,
    message,
    time: Date.now(),
    userId: user,
    name: user,
    interaction
  };

  try {
    await addDoc(rawMessagesRef, document);
  } catch (error: any) {
    logsToAwsCloudWatch(error?.message);
  }
};

export const useGeneratedResponses = ({ feature, streamId }: UseGeneratedResponsesProps) => {
  useEffect(() => {
    if (!feature) return;

    if ([
      'PDF Slide',
      'wheel',
      'newWheel',
      'textTrack',
      'fantasticFans'
    ].includes(feature.type)) return;

    generateResponse(
      feature.title,
      feature.type,
      feature.options?.map((option) => `${feature.setting?.prefix || ''}${option.option_number}`).join(','),
      streamId
    ).then(() => {
      console.log('generated responses');
    }).catch((err) => {
      console.log(err);
    });
  }, [feature]);

  const queue = useRef<Comment[]>([]);

  useEffect(() => {
    if (!streamId) return;

    const collectionRef = collection(db, 'streams', streamId, 'generatedMessages');

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      for (const change of snapshot.docChanges()) {
        if (change.type === 'added') {
          const data: any = change.doc.data();

          queue.current.push({
            id: change.doc.id,
            ...data
          });
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [streamId]);

  useEffect(() => {
    if (!feature) return;

    const interval = setInterval(() => {
      const filteredQueue = queue.current.filter(item => item.interaction === feature.type);
      const item = filteredQueue.shift();
      queue.current = filteredQueue;

      if (!item || item.interaction !== feature.type) return;

      onComment({
        streamId,
        message: item.message,
        user: item.userId,
        interaction: item.interaction as InteractionType
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [feature]);
};

interface Comment {
  id: string;
  interaction: string;
  message: string;
  name: string;
  time: number;
  userId: string;
  upvotes: number;
}
