import Button from '@atoms/new/button';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Tooltip from '@atoms/new/tooltip';

import { getOpenAIQuestions } from '@services/openai.service';
import { Skeleton } from '@nextui-org/skeleton';
import { Block } from '@components/Presentation/ai/generated-block';
import { TryOutSection } from './try-out-section';
import { SearchableDropdown } from './SearchableDropdown';

import { InputWithAutocomplete } from './input-with-autocomplete';
import { type BlockParams, type AutocompleteItem, interactionsMap } from '.';
import { useQuery } from '@tanstack/react-query';
import { getGenAiDefaultAudience } from '@services/generative-ai';
import { uniqBy } from 'lodash';
import { Checkbox } from '@nextui-org/react';

const staticFeatures = ['newWheel', 'Quick Questions', 'textTrack', 'fantasticFans', 'linkLibrary'];

export interface GenAiMainContentProps {
  isWebsite?: boolean;
  showResult: boolean;
  topic: string;
  setTopic: (value: string) => void;
  selectedBlocks: string[];
  setSelectedBlocks: (value: string[]) => void;
  autocomplete: AutocompleteItem[];
  query: string;
  isPending: boolean;
  fetchSlides: (query: string) => void;
  setPage: any;
  onQueryChange: (value: string) => void;
  setShowAutoComplete: (value: boolean) => void;
  showAutoComplete: boolean;
  setAudience: (value: string) => void;
  audience: string;
  isError: boolean;
  result: BlockParams[];
  setResult: any;
  setGenerateNewIsLoading: any;
  generateNewIsLoading: any;
  compact?: boolean;
  onAutocompleteClick: (value: AutocompleteItem) => void;
  selectionMode?: 'single' | 'multiple';
  onDeleteAutoComplete: (value: string) => void;
  tryOutHeaderTitle?: string;
  isExternalLink?: boolean;
  buckets?: any[];
  onClear?: () => void;
  onSelectAll?: () => void;
  inputPlaceholder?: string;
  buttonText?: string;
  showTryOut?: boolean;
  isOnBoarding?: boolean;
}

export const GenAiMainContent = ({
  isWebsite = false,
  showResult,
  topic,
  setTopic,
  selectedBlocks,
  setSelectedBlocks,
  autocomplete,
  query,
  isPending,
  fetchSlides,
  setPage,
  onQueryChange,
  setShowAutoComplete,
  showAutoComplete,
  setAudience,
  audience,
  isError,
  result,
  setGenerateNewIsLoading,
  generateNewIsLoading,
  setResult,
  onAutocompleteClick,
  compact = false,
  selectionMode = 'multiple',
  onDeleteAutoComplete,
  tryOutHeaderTitle,
  isExternalLink = false,
  buckets = [],
  onClear = () => {},
  onSelectAll = () => {},
  inputPlaceholder,
  buttonText,
  showTryOut = true,
  isOnBoarding = false
}: GenAiMainContentProps) => {
  const { data: audienceData, isLoading: audienceIsLoading } = useQuery({
    queryKey: ['getAudience'],
    queryFn: () => getGenAiDefaultAudience(),
  });

  return (
    <>
      <div
        className={`flex flex-col gap-1 bg-white rounded-xl z-20 ${compact ? 'mb-6' : 'sticky top-0'} ${
          isWebsite ? '!mb-10' : ''
        } ${selectionMode === 'single' ? 'pb-6 pt-4' : ''}`}
      >
        {!isWebsite && (
          <div className="flex gap-2">
            <SearchableDropdown
              isLoading={audienceIsLoading}
              selected={audience}
              onChange={(value) => {
                // setTopic('') is used to show arrow instead of regenerate
                setTopic('');
                setAudience(value);
              }}
              trigger={
                <Button
                  size="sm"
                  kind="secondary"
                  endContent={<KeyboardArrowDownRoundedIcon fontSize="small" />}
                  startContent={<PeopleAltRoundedIcon fontSize="small" />}
                >
                  {audience || 'Specify audience'}
                </Button>
              }
              options={audienceData?.entity?.audience || []}
            />
          </div>
        )}
        <InputWithAutocomplete
          autocomplete={autocomplete}
          isOnBoarding={isOnBoarding}
          onDeleteAutoComplete={onDeleteAutoComplete}
          inputProps={{
            onFocus: () => {
              if (!isOnBoarding) return;
              setShowAutoComplete(query.length === 0);
            },
            onBlur: () => {
              if (showAutoComplete || !isOnBoarding) return;
              setShowAutoComplete(false);
            },
            maxLength: 256,
            onKeyDown: (e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setTopic(query);
                fetchSlides(query);
              }
            },
            placeholder: inputPlaceholder || (isWebsite
              ? 'Enter a topic (eg. brand storytelling, all-hands, macroeconomics, minecraft)'
              : 'Enter a topic'),
            classNames: isWebsite
              ? {
                inputWrapper: 'h-16 border dark:bg-white shadow-none p-1',
                input: 'text-lg placeholder:text-grey-400 px-2 ',
              }
              : {},
            endContent:
              !showResult || query !== topic ? (
                <Button
                  className={`mr-0.5 ${isWebsite ? 'w-13.5 h-12' : ''} ${
                    isExternalLink ? 'w-40' : 'w-26'
                  }`}
                  size="sm"
                  isIconOnly={!isExternalLink}
                  isDisabled={query.length === 0 || isPending}
                  isLoading={isPending}
                  onClick={() => {
                    setTopic(query);
                    fetchSlides(query);
                  }}
                  endContent={
                    isPending ? null : isExternalLink ? null : (
                      <ArrowForwardRoundedIcon />
                    )
                  }
                >
                  {buttonText || (isExternalLink ? 'Simulate Now' : 'Submit')}
                </Button>
              ) : isPending ? null : (
                <Tooltip content="Regenerate">
                  <RefreshRoundedIcon
                    className="mr-3 cursor-pointer text-grey-900"
                    fontSize="small"
                    onClick={() => {
                      setPage((prev: number) => prev + 1);
                      fetchSlides(query);
                    }}
                  />
                </Tooltip>
              ),
          }}
          query={query}
          onQueryChange={onQueryChange}
          showAutoComplete={showAutoComplete}
          isLoading={isPending}
          setShowAutoComplete={setShowAutoComplete}
          onAutocompleteClick={onAutocompleteClick}
        />

        {showResult && result?.length !== 0 && (
          <>
            {selectionMode === 'multiple' && (
              <div className="flex items-center justify-end gap-2.5 mt-4 mb-2">
                <Checkbox
                  color='danger'
                  disabled={false}
                  onClick={onSelectAll}
                  isSelected={selectedBlocks.length === result.length}
                >
                  <p className='text-sm font-normal text-grey-900'>
                  Select all
                  </p>
                </Checkbox>

                <div className='text-sm font-normal text-grey-400'>•</div>

                <p className='cursor-pointer text-blue-600' onClick={onClear}>Clear</p>
              </div>
            )}
          </>
        )}
      </div>

      {showResult &&
        isPending &&
        new Array(5)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              className={`dark:bg-grey-200 w-full mt-4 ${
                index % 2 === 0 ? 'h-108' : 'h-52'
              }  rounded-md`}
            />
          ))}

      {showResult && result?.length !== 0 && (
        <>
          <div className="flex flex-col gap-4">
            {result?.map((block, index) => (
              <div className={result.length === index + 1 ? 'mb-2' : ''}>
                {generateNewIsLoading[index] && (
                  <Skeleton className="w-full h-40 rounded-md mb-2" />
                )}
                <Block
                  onClick={() => {
                    if (selectedBlocks.includes(block.title)) {
                      setSelectedBlocks(
                        selectedBlocks.filter((i) => i !== block.title)
                      );
                    } else {
                      if (selectionMode === 'single') {
                        setSelectedBlocks([block.title]);
                      } else {
                        setSelectedBlocks([...selectedBlocks, block.title]);
                      }
                    }
                  }}
                  isGenerated={!staticFeatures.includes(block.feature)}
                  isSelected={selectedBlocks.includes(block.title)}
                  key={index}
                  {...block}
                  feature={interactionsMap[block.feature as keyof typeof interactionsMap]?.title}
                  icon={interactionsMap[block.feature as keyof typeof interactionsMap]?.icon}
                  isLoading={generateNewIsLoading[index]}
                  onGenerateNew={async () => {
                    try {
                      setGenerateNewIsLoading({
                        [index]: true,
                      });

                      const res = await getOpenAIQuestions({
                        audience,
                        count: 3,
                        page: 0,
                        topic: query,
                        featureType: block.feature,
                      });

                      if (!res.status) return;

                      const uniqueBlock = res?.entity?.filter(
                        (item: any) => !result.find((i) => i.title === item.title)
                      );

                      if (uniqueBlock.length === 0) return;

                      const newBlock = {
                        ...block,
                        title: uniqueBlock[0]?.title,
                        options: uniqueBlock[0]?.options,
                      };

                      setResult((prev: BlockParams[]) =>
                        uniqBy(
                          [
                            ...prev.slice(0, index),
                            newBlock,
                            ...prev.slice(index),
                          ],
                          'title'
                        )
                      );
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setGenerateNewIsLoading({
                        [index]: false,
                      });
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {showTryOut && !showResult && (
        <TryOutSection
          buckets={buckets}
          tryOutHeaderTitle={tryOutHeaderTitle}
          isWebsite={isWebsite}
          compact={compact}
          onClick={onAutocompleteClick}
        />
      )}

      {isError && (
        <div className="flex items-center justify-center w-full h-52">
          <p className="text-grey-800 text-sm font-semibold">
            Oops! Something went wrong
          </p>
        </div>
      )}
    </>
  );
};
