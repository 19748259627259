import { useEffect, useState } from 'react';
import { pushDataLayerForEvent } from '@lib/gtag';
import Tooltip from '@molecules/Tooltip';

const PollOption = ({
  optionNumber,
  name,
  weight,
  color = '#F97B5F',
  option,
  id,
  count,
  totalCount,
  currentRosFeature,
  pollOptions,
  pollColors,
  pollSettings,
  relativePercentage,
  fontFamily
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [parentContainer, setParentContainer] = useState({
    height: 'xl:h-10 h-8',
    margin: '2xl:mb-3 xl:mb-1.5 mb-0.5'
  });

  const { type } = currentRosFeature;
  useEffect(() => {
    totalCount > 0 && pushDataLayerForEvent('populate_comments_power_poll');
  }, [totalCount > 0]);

  const getNoOfVotes = () => {
    if (count === 1) { return `${count} vote`; }
    return `${count} votes`;
  };

  const [optionNumContainer, setOptionNumContainer] = useState('xl:h-10 xl:w-10 h-8 w-8');

  const [fontSize, setFontSize] = useState({
    name: '2xl:text-2.5xl xl:text-2xl xl:leading-8 text-lg leading-4',
    weight: '2xl:text-2xl 2xl:leading-8 xl:text-base xl:leading-5  text-xs leading-6',
    optionNumber: '2xl:text-2xl 2xl:leading-8 xl:text-2xl xl:leading-7.5 text-base leading-4'
  });

  const updateStyle = () => {
    if (!pollOptions) return;
    if (pollOptions.length > 8 && pollOptions.length <= 10) {
      setFontSize({
        name: '2xl:text-2.5xl xl:text-2xl xl:leading-8 text-lg leading-4',
        weight: '2xl:text-2xl 2xl:leading-8 xl:text-base xl:leading-5  text-xs leading-6',
        optionNumber: '2xl:text-2xl 2xl:leading-8 xl:text-2xl xl:leading-7.5 text-base leading-4'
      });
      setOptionNumContainer('xl:w-10 w-8');
      setParentContainer('h-full');
    } else if (pollOptions.length > 6 && pollOptions.length <= 8) {
      setFontSize({
        name: '2xl:text-4xl 2xl:leading-14 xl:text-3xl xl:leading-9 text-xl leading-6',
        weight: '2xl:text-3xl 2xl:leading-10 xl:text-xl xl:leading-6 text-lg leading-4',
        optionNumber: '2xl:text-3xl 2xl:leading-8 xl:text-2xl xl:leading-7 text-lg leading-4'
      });
      setOptionNumContainer('2xl:w-12 xl:w-10 w-8');
      setParentContainer('h-full');
    } else if (pollOptions.length > 4 && pollOptions.length <= 6) {
      setFontSize({
        name: '2xl:text-5xl 2xl:leading-15 xl:text-4xl xl:leading-10 text-2xl leading-6',
        weight: '2xl:text-3xl 2xl:leading-10  xl:text-base xl:leading-5 text-sm leading-5',
        optionNumber: '2xl:text-3xl 2xl:leading-8 xl:text-2xl xl:leading-7 text-base leading-4'
      });
      setOptionNumContainer('2xl:w-12 xl:w-10 w-8 ');
      setParentContainer('h-full');
    } else if (pollOptions.length > 2 && pollOptions.length <= 4) {
      setFontSize({
        name: '2xl:text-5.5xl 2xl:leading-15 xl:text-5xl xl:leading-12 text-3xl leading-12',
        weight: '2xl:text-3xl 2xl:leading-10 xl:text-xl xl:leading-7.5 text-sm leading-5',
        optionNumber: '2xl:text-4xl 2xl:leading-13.5 xl:text-3xl xl:leading-8 text-3xl leading-8'
      });
      setOptionNumContainer('2xl:w-18 xl:w-12 w-12 ');
      setParentContainer('h-full');
    } else {
      setFontSize({
        name: '2xl:text-5.5xl 2xl:leading-15 xl:text-5xl xl:leading-12 text-3xl leading-12',
        weight: '2xl:text-3xl 2xl:leading-10 xl:text-xl xl:leading-7.5 text-sm leading-5',
        optionNumber: '2xl:text-4xl 2xl:leading-13.5 xl:text-3xl xl:leading-8 text-3xl leading-8'
      });
      setOptionNumContainer('2xl:w-18 xl:w-13.5 w-12');
      setParentContainer('xl:h-31 h-20');
    }
  };

  const textColor = currentRosFeature.type === 'pollWithOptions' ? pollColors.pollWithOptionsLabelColor : pollColors.pollLabelColor;

  useEffect(() => {
    updateStyle();
  }, [currentRosFeature.type, pollOptions]);
  return <>
    {
      currentRosFeature
      && <div className={`w-full flex items-center ${parentContainer} z-10`}>

        <div
          style={{ background: color, color: textColor || 'white' }}
          className={`flex ${optionNumContainer} h-full items-center text-white justify-center rounded mr-3`}>
          <span style={fontFamily} className={`${fontSize.optionNumber}  test-base font-medium text-center`}>{optionNumber}</span>
        </div>

        <div className='w-full h-full flex'>
          <div id={name} className={' w-full h-full flex items-center relative pr-3'}>
            <div
              onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}
              style={{ width: `${relativePercentage}% `, background: color }}
              className={'h-full rounded-tr rounded-br cursor-pointer absolute left-0 top-0 z-0'}>

            </div>
            <div className={' w-full h-full flex items-center  relative z-10'}>
              {
                weight === 0 && <div onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)} style={{ width: `${0.4}%`, background: color }} className={'w-full h-full rounded-tr rounded-br cursor-pointer'}></div>
              }

              <div id={`option-${id}`} style={fontFamily} className={`flex items-center gap-4 ${fontSize.name}  px-4 font-normal break-all whitespace-normal`} >

                <p style={{ color: `${currentRosFeature.type === 'pollWithOptions' ? pollColors.pollWithOptionsLabelColor : pollColors.pollLabelColor}` }} >{name}</p>

                <p className={`${fontSize.weight} break-normal`} style={{ color: `${currentRosFeature.type === 'pollWithOptions' ? pollColors.pollWithOptionsLabelColor : pollColors.pollLabelColor}` }}>{pollSettings?.DisplayStyle === 'percentage' ? `${weight}%` : getNoOfVotes()}</p>

              </div>

            </div>

            <div className='relative'>
              {
                showTooltip && <Tooltip
                  type={((option.count !== 0 && type === 'freeForm') || (option.weight !== 0 && type === 'pollWithOptions')) ? 'poll' : 'zeroPercentage'}
                  directions={'bottom'}
                  arrowDirections={'top'}
                  className2='p-2 font-medium text-xs leading-2.5 rounded-lg text-white shadow-comment-overlay bg-ToolTip backdrop-filter w-max'
                  className1='font-medium text-xs leading-2.5 border-gray-50 w-max'>  {`${option.count !== undefined ? option.count : 0} vote(s) out of ${totalCount || 0}`}</Tooltip>
              }
            </div>
          </div >
        </div >
      </div >
    }
  </>;
};

export default PollOption;
