import { useSpring, animated } from 'react-spring';
import React, { useRef } from 'react';

export const getFontColor = (backgroundColor = '#000000') => {
  if (backgroundColor.charAt(0) === '#') {
    backgroundColor = backgroundColor.slice(1);
  }

  const red = parseInt(backgroundColor.substr(0, 2), 16);
  const green = parseInt(backgroundColor.substr(2, 2), 16);
  const blue = parseInt(backgroundColor.substr(4, 2), 16);

  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  if (brightness > 125) {
    return '#000000';
  }

  return '#FFFFFF';
};

class TileManager {
  constructor(container, currentRosFeature, chromaColor, textColor = '#111111') {
    this.container = container;
    this.tiles = [];
    this.columns = [];
    this.onTilesUpdated = () => {};
    this.currentRosFeature = currentRosFeature;
    this.chromaColor = chromaColor;
    this.commentQueue = [];
    this.lastTileColor = '';
    this.textColor = textColor;
    this.currentRosFeatureId = currentRosFeature.id;

    this.TILE_GAP = 15;
    this.DROP_SPEED = this.calculateDropSpeed();
    this.MAX_COLS = 3;
    this.INITIAL_OFFSET = 100;
    this.BOTTOM_PADDING = 20;
    this.BOTTOM_GAP = 1;
    this.MIN_TILE_HEIGHT = 150;

    this.containerHeight = container.getBoundingClientRect().height;
    this.maxTileHeight = this.containerHeight * 0.5;
    this.numCols = this.calculateNumCols();
    this.lastTileId = 0;

    this.animationFrame = null;
    this.measureTextHeight = null;
  }

  getCurrentRosFeatureId() {
    return this.currentRosFeatureId;
  }

  setCurrentRosFeatureId(currentRosFeatureId) {
    this.currentRosFeatureId = currentRosFeatureId;
  }

  calculateDropSpeed() {
    const containerHeight = this.container.getBoundingClientRect().height;
    const minSpeed = 0.6;
    return Math.max(containerHeight / 500, minSpeed);
  }

  setContainer(container) {
    this.container = container;
    this.numCols = this.calculateNumCols();
  }

  setContainerHeight(containerHeight) {
    this.containerHeight = containerHeight;
    this.maxTileHeight = this.containerHeight * 0.5;
    this.numCols = this.calculateNumCols();
    this.DROP_SPEED = this.calculateDropSpeed();
  }

  calculateNumCols() {
    const containerWidth = this.container.getBoundingClientRect()?.width;
    return Math.min(Math.floor(containerWidth / 300), this.MAX_COLS) || 3;
  }

  getNumCols() {
    return this.numCols;
  }

  setCurrentRosFeature(feature) {
    this.currentRosFeature = feature;
    this.chromaColor = feature.color.talkingTilesBgColor;
    this.updateExistingTiles();
    this.numCols = this.calculateNumCols();
  }

  updateExistingTiles() {
    this.tiles.forEach(tile => {
      tile.backgroundColor = this.getRandomColor();
      tile.fontColor = this.textColor;
    });
    this.onTilesUpdated([...this.tiles]);
  }

  createTile(comment, index, props = {}) {
    const words = comment.words || comment;
    const username = comment.username || '';
    const { height: tempTileHeight, fontSize, userNameFontSize } = this.createTempTile(words, username);
    const backgroundColor = this.getRandomColor();
    const containerWidth = this.container.getBoundingClientRect().width;
    const numCols = this.getNumCols();
    const columnWidth = containerWidth / numCols - this.TILE_GAP;

    const tile = {
      id: this.lastTileId,
      comment_id: comment?.comment_id || '',
      words,
      username,
      backgroundColor,
      fontColor: this.textColor,
      width: columnWidth,
      column: this.lastTileId % numCols,
      top: -this.maxTileHeight * (index + 1),
      targetTop: -this.maxTileHeight * (index + 1),
      height: tempTileHeight,
      visible: true,
      settled: false,
      isNew: true,
      fontSize,
      userNameFontSize,
      ...props
    };

    this.lastTileId++;
    return tile;
  }

  dropTile({ comments, numCols = 3 }) {
    if (this.tiles.some(tile => tile.top < 0)) {
      this.commentQueue.push(...comments);
      return;
    }

    const newTiles = comments.map((comment, index) => this.createTile(comment, index, { isNew: true }));
    this.preCalculatePositions(newTiles, numCols);
    this.tiles = [...newTiles, ...this.tiles];
    this.reorganizeTiles();
    this.startAnimation();
  }

  preCalculatePositions(newTiles, numCols) {
    const columnHeights = Array(numCols).fill(0);
    newTiles.forEach(tile => {
      const column = tile.column;
      tile.top = -this.INITIAL_OFFSET - columnHeights[column] - tile.height - this.TILE_GAP;
      columnHeights[column] += tile.height + this.TILE_GAP;
    });
  }

  reorganizeTiles(tiles = null) {
    const tilesToReorganize = tiles || this.tiles;
    this.numCols = this.calculateNumCols();
    this.columns = Array.from({ length: this.numCols }, () => []);

    tilesToReorganize.forEach(tile => {
      this.columns[tile?.column >= this.numCols ? tile.column - 1 : tile?.column || 0].push(tile);
    });

    this.columns.forEach(column => {
      column.sort((a, b) => a.top - b.top);
      column.forEach((tile, index) => {
        tile.isBottom = index === column.length - 1;
      });

      let accumulatedHeight = 0;
      for (let i = column.length - 1; i >= 0; i--) {
        const tile = column[i];
        if (tile.isNew) {
          tile.targetTop = this.containerHeight - (this.BOTTOM_PADDING + this.BOTTOM_GAP + accumulatedHeight + tile.height);
        }
        accumulatedHeight += tile.height + this.TILE_GAP;
      }
    });

    this.onTilesUpdated([...tilesToReorganize]);
  }

  startAnimation() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
    this.animationFrame = requestAnimationFrame(() => this.animateTiles());
  }

  animateTiles() {
    cancelAnimationFrame(this.animationFrame);
    const containerHeight = this.containerHeight - this.BOTTOM_PADDING - this.BOTTOM_GAP;
    const halfContainerHeight = containerHeight * 0.7;
    let hasMovingTiles = false;

    this.columns.forEach((column, columnIndex) => {
      let settledHeight = 0;
      let totalHeight = 0;

      for (let i = column.length - 1; i >= 0; i--) {
        const tile = column[i];
        totalHeight += tile.height + this.TILE_GAP;

        if (tile.settled) {
          settledHeight += tile.height + this.TILE_GAP;
        } else {
          hasMovingTiles = true;
          const previousTop = tile.top;
          const maxTop = containerHeight - totalHeight;
          const moveDistance = Math.min(this.DROP_SPEED, maxTop - tile.top);
          tile.top = tile.top + moveDistance;

          if (tile.top >= maxTop) {
            tile.top = maxTop;
            tile.settled = true;
            settledHeight += tile.height + this.TILE_GAP;
          }

          tile.targetTop = tile.top;
          if (tile.top === previousTop && tile.top < 0) {
            tile.top += this.DROP_SPEED;
            tile.targetTop = tile.top;
          }
        }
      }
      if (settledHeight > halfContainerHeight) {
        this.removeBottomTile();
      }
    });

    if (this.commentQueue.length > 0) {
      const queuedComments = this.commentQueue.splice(0, this.commentQueue.length);
      this.dropTile({ comments: queuedComments, numCols: this.numCols });
    }
    if (!hasMovingTiles) {
      cancelAnimationFrame(this.animationFrame);
      this.tiles.forEach(tile => {
        tile.isNew = false;
      });
    } else {
      this.animationFrame = requestAnimationFrame(() => this.animateTiles());
    }

    this.onTilesUpdated([...this.tiles]);
  }

  getColumnHeight(column) {
    return column.reduce((sum, tile) => sum + tile.height + this.TILE_GAP, 0);
  }

  getRemovedCommentIds() {
    const saveStateKey = `talking-tiles-state_${this.currentRosFeature.id}`;
    const saveStateData = localStorage.getItem(saveStateKey);
    const saveStateObject = saveStateData ? JSON.parse(saveStateData) : { comments: [], removedCommentIds: [] };
    return saveStateObject.removedCommentIds;
  }

  async removeBottomTile() {
    const allColumnsHaveSettledBottom = this.columns.every(column =>
      column.length > 0 && column.some(tile => tile.isBottom && tile.settled)
    );

    const removedCommentIds = [];

    if (allColumnsHaveSettledBottom) {
      for (const column of this.columns) {
        if (column && column.length > 0) {
          const bottomTileIndex = column.findIndex(tile => tile.isBottom && tile.settled);

          if (bottomTileIndex !== -1) {
            const removedTile = column[bottomTileIndex];
            removedCommentIds.push(removedTile.comment_id);
            this.tiles = this.tiles.filter(tile => tile.id !== removedTile.id);
            column.splice(bottomTileIndex, 1);

            if (column.length > 0) {
              const newBottomTile = column[column.length - 1];
              newBottomTile.isBottom = true;

              let accumulatedHeight = this.BOTTOM_GAP;
              column.forEach(tile => {
                tile.targetTop = this.containerHeight - this.BOTTOM_PADDING - accumulatedHeight - tile.height;
                tile.settled = false;
                tile.isNew = false;
                accumulatedHeight += tile.height + this.TILE_GAP;
              });
            }
          }
        }
      }
      const saveStateKey = `talking-tiles-state_${this.currentRosFeature.id}`;
      const saveStateData = localStorage.getItem(saveStateKey);
      const saveStateObject = saveStateData ? JSON.parse(saveStateData) : { comments: [], removedCommentIds: [] };
      saveStateObject.removedCommentIds = [...new Set([...saveStateObject?.removedCommentIds || [], ...removedCommentIds])];
      localStorage.setItem(saveStateKey, JSON.stringify(saveStateObject));
      this.onTilesUpdated([...this.tiles]);
    }
  }

  getRandomColor() {
    const bgColor1 = this.currentRosFeature.color.talkingTileBgColor1 || '#8942DF';
    const bgColor2 = this.currentRosFeature.color.talkingTileBgColor2 || '#56CB77';
    const bgColor3 = this.currentRosFeature.color.talkingTileBgColor3 || '#E2A848';
    const defaultPalette = ['#8942DF', '#56CB77', '#E2A848'];
    const userPalette = [bgColor1, bgColor2, bgColor3];
    const colors = this.currentRosFeature?.color?.multipleColors ? defaultPalette : userPalette;

    if (!this.lastTileColor) {
      this.lastTileColor = colors[Math.floor(Math.random() * colors.length)];
    } else {
      let availableColors = this.currentRosFeature?.color?.multipleColors ? colors.filter(color => color !== this.lastTileColor) : colors;
      this.lastTileColor = availableColors[Math.floor(Math.random() * availableColors.length)];
    }

    return this.lastTileColor;
  }

  setOnTilesUpdated(callback) {
    this.onTilesUpdated = callback;
  }

  createTempTile(word, username) {
    const maxCharacters = 300;
    const containerWidth = this.container?.getBoundingClientRect().width || 1000;
    const truncatedText = word.length > maxCharacters
      ? word.slice(0, maxCharacters) + '...'
      : word;
    const fontSize = containerWidth < 1000 ? '100%' : '150%';
    let userNameFontSize = containerWidth < 1000 ? '70%' : '90%';
    const fontFamily = this.currentRosFeature.setting.FontStyle || 'monospace';
    const createElement = document.createElement('div');
    const showUsername = !this.currentRosFeature?.setting?.showUserName;
    createElement.style.position = 'absolute';
    createElement.style.left = '-9999px';
    createElement.style.top = '0';
    createElement.style.width = `${containerWidth / this.numCols - this.TILE_GAP}px`;
    createElement.style.padding = '20px';
    createElement.style.fontFamily = fontFamily;
    createElement.style.fontSize = fontSize;
    createElement.style.lineHeight = '1.3';
    createElement.style.wordWrap = 'break-word';
    createElement.style.overflowWrap = 'break-word';
    createElement.style.display = 'flex';
    createElement.style.flexDirection = 'column';
    createElement.style.justifyContent = 'space-between';

    const content = document.createElement('p');
    content.style.marginTop = '15px';
    content.style.marginBottom = '5px';
    content.style.textAlign = 'center';
    content.innerText = truncatedText;

    createElement.appendChild(content);

    if (username && showUsername) {
      const usernameElement = document.createElement('div');
      usernameElement.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      usernameElement.style.padding = '5px 10px';
      usernameElement.style.borderRadius = '8px';
      usernameElement.style.alignSelf = 'flex-end';
      usernameElement.style.marginTop = '10px';

      const usernameText = document.createElement('p');
      usernameText.style.color = '#FFFFFF';
      usernameText.style.margin = '0';
      usernameText.style.fontSize = userNameFontSize;
      usernameText.style.fontWeight = 'bold';
      usernameText.style.fontFamily = fontFamily;
      usernameText.innerText = username;

      usernameElement.appendChild(usernameText);
      createElement.appendChild(usernameElement);
    }

    this.container.appendChild(createElement);

    const height = createElement.scrollHeight;
    this.container.removeChild(createElement);

    return { height, fontSize, userNameFontSize };
  }

  clearTiles() {
    this.tiles = [];
    this.columns = [];
    this.cancelAnimation();
    this.commentQueue = [];
    this.onTilesUpdated([]);
  }

  cancelAnimation() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = null;
    }
  }

  resizeContainer(container) {
    this.container = container || this.container;
    this.containerHeight = container.getBoundingClientRect().height;
    this.maxTileHeight = this.containerHeight * 0.5;
    this.numCols = this.calculateNumCols();
    this.DROP_SPEED = this.calculateDropSpeed();

    const currentTilePosition = this.getTilesState();
    this.clearTiles();
    this.dropTile({ comments: currentTilePosition, numCols: this.numCols });
  }

  setMeasureTextHeight(callback) {
    this.measureTextHeight = callback;
  }

  destroy() {
    this.clearTiles();
  }

  getTilesState() {
    return this.tiles.map(tile => ({
      comment_id: tile.comment_id || '',
      id: tile.id,
      words: tile.words,
      username: tile.username,
      backgroundColor: tile.backgroundColor,
      fontColor: tile.fontColor,
      width: tile.width,
      column: tile.column,
      top: tile.top,
      targetTop: tile.targetTop,
      height: tile.height,
      visible: tile.visible,
      settled: tile.settled,
      isNew: false,
      isSaved: true,
    }));
  }

  restoreTilesState(savedState) {
    this.tiles = savedState.map((comment, index) => this.createTile(comment, index, {
      isNew: false,
      isSaved: true,
      top: comment.top,
      backgroundColor: comment.backgroundColor,
      fontColor: comment.fontColor,
      column: comment.column,
    }));
    this.reorganizeTiles();
    this.startAnimation();
  }
}

export const AnimatedTile = ({ tile, tileGap, currentRosFeature }) => {
  const contentRef = useRef(null);

  const showUsername = currentRosFeature?.setting?.showUserName;
  const entryProps = useSpring({
    from: { top: tile.settled ? tile.targetTop : -tile.height, opacity: 1 },
    to: {
      top: tile.top,
      opacity: tile.isFadingOut ? 0 : 1
    },
    config: {
      tension: 170,
      friction: 26,
      duration: tile.isFadingOut ? 100 : undefined,
    },
    immediate: !(tile.isNew) && !tile.isFadingOut,

  });

  const fontFamily = currentRosFeature.setting.FontStyle || 'monospace';

  const truncatedText = tile.words.length > 300 ? tile.words.slice(0, 300) + '...' : tile.words;

  const maxUsernameLength = 30;
  const truncatedUsername = tile.username.length > maxUsernameLength ? tile.username.slice(0, maxUsernameLength) + '...' : tile.username;

  return (
    <animated.div
      style={{
        ...entryProps,
        position: 'absolute',
        left: tile.column * (tile.width + tileGap) + tileGap / 2,
        width: tile.width,
        height: `${tile.height}px`,
        backgroundColor: tile.backgroundColor,
        padding: '0px 10px 10px 10px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: tile.isNew ? 'none' : 'top 0.1s linear',
        overflow: 'hidden',
      }}
    >
      <div
        ref={contentRef}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <p
          ref={contentRef}
          style={{
            color: tile.fontColor,
            margin: 0,
            textAlign: 'center',
            fontSize: tile.fontSize,
            lineHeight: '1.3',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            fontFamily,

          }}
        >
          {truncatedText}
        </p>
      </div>
      {tile.username && showUsername && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '5px 10px',
            borderRadius: '8px',
            alignSelf: 'flex-end',
            marginTop: '0px',
          }}
        >
          <p style={{ color: '#FFFFFF', margin: 0, fontSize: tile?.userNameFontSize || '16px', fontWeight: 'bold', fontFamily }}>
            {truncatedUsername}
          </p>
        </div>
      )}
    </animated.div>
  );
};

export default TileManager;
