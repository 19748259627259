const OptionOrderType = ({ index, type = 'number', prefix = '', chartColor = '#E8634D', textColor = '#FFFFFF' }: {
  index: number;
  type?: 'number' | 'letter';
  prefix?: string;
  chartColor?: string;
  textColor?: string;
}) => {
  const optionLabel = type === 'number' ? index + 1 : String.fromCharCode(65 + index);
  const label = prefix ? `${prefix}${optionLabel}` : optionLabel;
  return (
    <div
      style={{ background: chartColor, color: textColor }}
      className="px-1 h-full 2xl:min-w-18 xl:min-w-13.5 min-w-12 grid place-items-center rounded-md overflow-hidden ">
      <span className="truncate px-3 font-Inter  2xl:text-4xl xl:text-3xl text-3xl">
        {label}
      </span>
    </div>
  );
};

export default OptionOrderType;
