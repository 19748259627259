import { prepareROSPayload } from '@services/utils.service';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { v4 } from 'uuid';
import { TeamsDataContext } from '../../context/TeamsContext';
import {
  createRosTemplate,
  deleteQuestion,
  deleteRosTemplate,
  getLiveStreamById,
  getTemplateTitle,
  updateLiveStreamSettings,
  updateLiveStreams
} from '@services/youtube-platform.service';
import { useRouter } from 'next/router';
import { getAuth } from '@services/identity.service';
import type {
  Interaction,
  InteractionColors,
  InteractionOptions,
  InteractionSettings,
  InteractionType,
  PanelButton,
  Session,
  Template
} from '@components/Presentation/types';
import useSlideHistory from '../hooks/useSlideHistory';
import { panelBtnEnum, defaultInteractionConfig } from '../constants';
import { pushDataLayerForEvent } from '@lib/gtag';
import { getDefaultTitle, notify, getDefaultOptions } from '../helpers';
import useGlobalSettings from '../hooks/useGlobalSettings';
import { type Theme } from '@components/themes/store/ThemeStore';
import useThemesAPI from '@components/themes/hooks/useThemesAPI';
import { cloneDeep } from 'lodash';

type TSlideHandlers = {
  applyToSimilarSlides?: () => void;
  onTitleChange?: (value: string) => void;
  onOptionChange?: (options: InteractionOptions[]) => void;
  updateTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateSetting: (
    key: keyof InteractionSettings,
    value: any
  ) => void;
  updateColor: (
    key: keyof InteractionColors,
    value: string | boolean
  ) => void;
  addOption: () => void;
  updateOptions: (
    index?: number,
    e?: React.ChangeEvent<HTMLInputElement>,
    choiceCircleOptions?: InteractionOptions[]
  ) => void;
  updateOptionsByName: (
    index?: number,
    e?: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  deleteOption: (index: number) => void;
  resetDesign: () => void;
};

type View = 'List' | 'Grid';
type SaveState = 'saved' | 'saving' | 'unsaved';

type RoSPageContextType = {
  selectedTheme?: Theme
  selectedThemeId: string | null;
  isLoadingTemplates: boolean;
  saveState: SaveState
  selectedPanelBtn: PanelButton | '';
  view: View;
  toggleView: (view?: View) => void;
  toggleSelectedPanelBtn: (buttonName: PanelButton | '') => () => void;
  slides: Interaction[];
  setSlides: React.Dispatch<React.SetStateAction<Interaction[]>>;
  selectedSlideIndex: number;
  showGenerateWithAIModal: boolean;
  setShowGenerateWithAIModal: (show: boolean) => void;
  updateSelectedSlideIndex: (index: number) => void;
  switchSlide: (slide: Interaction, index: number) => () => void;
  addNewSlide: (interactionType: InteractionType, slide?: Interaction) => void;
  deleteSlide: (index: number) => void;
  duplicateSlide: (index: number) => void;
  changeInteraction: (value: string | number) => void;
  selectedSlide: Interaction & { handler: TSlideHandlers };
  mergeOrReplaceTemplate: (
    template: Template,
    actionType: 'merge' | 'replace'
  ) => void;
  rosTitle: string;
  layoutMode: 'square' | 'rectangle';
  updateLayoutMode: (mode: 'square' | 'rectangle') => void
  updateRoSTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectPresentation: (template: Template) => Promise<any>;
  unLinkPresentation: () => Promise<any>;
  saveTemplate: (customPayload?: any) => Promise<any>;
  duplicateTemplate: () => void;
  deleteTemplate: () => void;
  session: Session | null;
  templateId: string | null;
  handleDnD: (firstIndex: number, secondIndex: number) => void;
  updateTheme: (theme: Theme) => void;
  onCopyInteractionLink: (index: number) => void;
};

const RoSPageContext = createContext<RoSPageContextType>({
  isLoadingTemplates: false,
  showGenerateWithAIModal: false,
  setShowGenerateWithAIModal: () => {},
  saveState: 'saved',
  templateId: null,
  selectedPanelBtn: '',
  toggleSelectedPanelBtn: () => () => {},
  view: 'List',
  toggleView: () => {},
  slides: [],
  setSlides: () => {},
  selectedSlideIndex: 0,
  updateSelectedSlideIndex: () => {},
  switchSlide: () => () => {},
  addNewSlide: () => {},
  deleteSlide: () => {},
  duplicateSlide: () => {},
  changeInteraction: () => {},
  // @ts-expect-error - default value
  selectedSlide: {},
  mergeOrReplaceTemplate: () => {},
  rosTitle: '',
  updateRoSTitle: () => {},
  selectPresentation: () => Promise.resolve(),
  unLinkPresentation: () => Promise.resolve(),
  saveTemplate: () => Promise.resolve(),
  duplicateTemplate: () => {},
  deleteTemplate: () => {},
  session: null,
  layoutMode: 'rectangle',
  updateLayoutMode: () => {},
  updateTheme: () => {},
  handleDnD: () => {},
  onCopyInteractionLink: () => {},
});

interface RoSContextProps {
  children: React.ReactNode;
  // toggleShowTemplate: (show: boolean) => void;
}

function RoSContext({ children }: RoSContextProps) {
  const [template, setTemplate] = useState<Template | null>(null);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [saveState, setSaveState] = useState<SaveState>('saved');
  const [rosTitle, setRosTitle] = useState<string>('Untitled Presentation');
  const [slides, setSlides] = useState<Interaction[]>([]);
  const [isEditable, setIsEditable] = useState(true);
  const [session, setSession] = useState<Session | null>(null);
  const router = useRouter();
  const [templateId, setTemplateId] = useState<string | null>(
    (router.query?.templateId as string) || null
  );
  const deletedSlides = useRef<string[]>([]);
  const [view, setView] = useState<'List' | 'Grid'>('List');
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(-1);
  const [selectedPanelBtn, setSelectedPanelBtn] = useState<PanelButton | ''>(
    ''
  );
  const slideHistory = useSlideHistory();
  const { teamContext } = useContext(TeamsDataContext);
  const auth = getAuth();
  const { getGlobalSettings, getGlobalColors, selectedTheme } = useGlobalSettings(selectedThemeId || '');
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const [layoutMode, setLayoutMode] = useState<'square' | 'rectangle'>('rectangle');

  const { applySelectedThemeApi } = useThemesAPI();

  const fetchTemplate = async (id: string) => {
    setIsLoadingTemplates(true);
    const templateRes = await getTemplateTitle(id, auth, teamContext?.oid);
    if (!templateRes.status && templateRes.message === 'Presentation not found!') {
      notify('Template not found!', 'error');
      router.replace('/presentations');
      return;
    }
    if (templateRes.status) {
      setSelectedThemeId(templateRes.entity.selected_theme_id);
      setTemplate(templateRes.entity);
      setRosTitle(templateRes.entity.title);
      setIsEditable(templateRes.entity.editable);
      setLayoutMode(templateRes.entity.layout_mode || 'rectangle');
      let newSlides = templateRes.entity.questions;
      if (router.query?.type === 'onboarding' && router.query?.templateId) {
        newSlides = newSlides?.map((question: Interaction) => {
          if (question.type !== 'PDF Slide') {
            question.setting = { ...(question.setting || {}), ...getGlobalSettings(question.type) };
            question.color = { ...(question.color || {}), ...getGlobalColors(question.type) };
          }
          if (['pollWithOptions', 'wheel',].includes(question.type) && question.options.length < 2) {
            if (question.options.length === 1) question.options.push({ id: v4(), body: 'Option 2' });
            else {
              question.options = getDefaultOptions(question.type);
            }
          }

          return ({
            ...question,
            id: undefined
          });
        });
      }
      setSlides(newSlides);
      if (templateRes.entity.questions.length > 0) {
        if (templateRes.entity.questions[0].type !== 'PDF Slide') {
          setSelectedPanelBtn('Content');
        } else setSelectedPanelBtn('');
        setSelectedSlideIndex(0);
      }
    }
    setIsLoadingTemplates(false);
    return templateRes;
  };
  const fetchSession = async () => {
    const fetchedSession = await getLiveStreamById(router.query.streamId, auth);
    if (!fetchedSession.status) return;
    setSession(fetchedSession.entity);
    if (fetchedSession.entity.template_id) {
      fetchTemplate(fetchedSession.entity.template_id);
    }
    setTemplateId(fetchedSession.entity.template_id);
    // if (!router.query.modal) {
    //   toggleShowTemplate(fetchedSession.entity.template_id === templateId);
    // }
  };

  const presentation = {
    isDirty: () => setSaveState('unsaved'),
    isClean: () => setSaveState('saved'),
    isSaving: () => setSaveState('saving')
  };

  useEffect(() => {
    if (router.query?.streamId && !templateId) { // if user routes from the session page
      fetchSession();
    }
    if (router.query?.templateId) { // if user edits a template
      fetchTemplate(router.query.templateId as string);
    }
    if (router.query?.type === 'onboarding' && !router.query?.templateId) { // setting map as default interaction for onboarding
      setSlides([
        {
          ...defaultInteractionConfig,
          color: {
            ...defaultInteractionConfig.color,
            ...getGlobalColors('map')
          },
          setting: {
            ...defaultInteractionConfig.setting,
            ...getGlobalSettings('map')
          },
          type: 'map'
        }
      ]);
      setSelectedSlideIndex(0);
      setSelectedPanelBtn('Content');
      presentation.isDirty();
    }
    if (router.query?.type === 'onboarding' && router.query?.templateId) { // if user edits a template from onboarding
      setTemplateId(router.query?.templateId as string);
    }
  }, [router.query]);

  const reset = () => {
    setRosTitle('Untitled Presentation');
    setSlides([]);
    setTemplateId(null);
    setIsEditable(true);
    setSelectedSlideIndex(-1);
    setSelectedPanelBtn('');
    slideHistory.flush();
    deletedSlides.current = [];
  };

  const updateRoSTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRosTitle(e.target.value);
    presentation.isDirty();
  };

  const toggleView = (view?: View) =>
    setView((prev) => view || (prev === 'List' ? 'Grid' : 'List'));

  const toggleSelectedPanelBtn = (buttonName: PanelButton | '') => () =>
    setSelectedPanelBtn(buttonName);

  const mergeOrReplaceTemplate = async (
    template: Template,
    actionType: 'merge' | 'replace'
  ) => {
    let title = rosTitle;
    if (rosTitle === 'Untitled Presentation') title = `${template.title}-copy`;
    const newSlides = template.questions!.map((question) => {
      if (question.type !== 'PDF Slide') {
        question.setting = { ...(question.setting || {}), ...getGlobalSettings(question.type) };
        question.color = { ...(question.color || {}), ...getGlobalColors(question.type) };
      }
      if (['pollWithOptions', 'wheel',].includes(question.type) && question.options.length < 2) {
        if (question.options.length === 1) question.options.push({ id: v4(), body: 'Option 2' });
        else {
          question.options = getDefaultOptions(question.type);
        }
      }

      return ({
        ...question,
        id: undefined
      });
    });
    if (actionType === 'merge') {
      const payload = prepareROSPayload(
        {
          id: templateId,
          title: title || 'Untitled presentation',
          createdBy: template?.created_by || auth.id || null,
          isEditable,
        },
        [...slides, ...newSlides],
        teamContext?.oid,
        [],
        !isEditable
      );
      const res = await saveTemplate(payload);
      if (!res.status) return;
      setSelectedSlideIndex(slides.length);
      setSelectedPanelBtn(res.entity.questions[slides.length].type === 'PDF Slide' ? '' : 'Content');
    }
    if (actionType === 'replace') {
      slideHistory.flush();
      if (isEditable) {
        // careful with this, it will delete all the slides from sample template
        slides.forEach((slide) => {
          if (slide.id) deletedSlides.current.push(slide.id);
        });
      }
      const payload = prepareROSPayload(
        {
          id: templateId,
          title: title || 'Untitled presentation',
          createdBy: template?.created_by || auth.id || null,
          isEditable
        },
        [...newSlides],
        teamContext?.oid,
        [],
        !isEditable
      );
      const res = await saveTemplate(payload);
      if (!res.status) return;
      setSelectedSlideIndex(0);
      setSelectedPanelBtn(res.entity.questions[0].type === 'PDF Slide' ? '' : 'Content');
    }
  };

  const selectPresentation = async (template: Template) => {
    if (!session) return;
    setSelectedThemeId(template.selected_theme_id || null);
    const response = await updateLiveStreams(session.id, {
      templateId: template.id
    });
    if (response.status) {
      setTemplateId(template.id);
      setRosTitle(template.title);
      setSlides(template.questions || []);
      setIsEditable(template.editable);
      setSelectedSlideIndex(0);
      if (
        template.questions &&
        template.questions.length > 0 &&
        template.questions[0]?.type !== 'PDF Slide'
      ) {
        setSelectedPanelBtn('Content');
      } else setSelectedPanelBtn('');

      pushDataLayerForEvent('update ros template', { templateId: template.id });
      notify('Template selected.');
    } else {
      notify('Something went wrong. Please try again.', 'error');
    }
    return response;
  };

  const unLinkPresentation = async () => {
    if (!session) return;
    const res = await updateLiveStreams(session.id, { templateId: null });
    if (res.status) {
      reset();
      fetchSession();
      notify('Template unlinked.');
      // toggleShowTemplate(true);
    } else {
      notify('Something went wrong. Please try again.', 'error');
    }
    return res;
  };

  const deletePendingSlides = async () => {
    if (deletedSlides.current.length > 0) {
      const res = await deleteQuestion(deletedSlides.current);
      if (!res.status) notify('Failed to delete slides.', 'error');
      deletedSlides.current = [];
    }
  };

  const getParsedSlides = () => {
    if (!isEditable) {
      return slides.map((slide) => ({ ...slide, id: undefined }));
    }
    let isDuplicatesRemoved = false;

    const newSlides = slides.map((slide) => {
      const seen = new Set();
      if (Array.isArray(slide.options) && slide.options.length > 0) {
        slide.options = slide.options.filter((option) => {
          if (seen.has(option.body)) {
            isDuplicatesRemoved = true;
            return false;
          };
          seen.add(option.body);
          return true;
        });
      }
      return slide;
    });

    if (isDuplicatesRemoved) notify('Duplicate options were removed.', 'info');

    return newSlides;
  };
  const saveTemplate = async (customPayload?: any) => {
    presentation.isSaving();
    pushDataLayerForEvent('save_template');
    await deletePendingSlides();

    if (session && selectedTheme) {
      await updateLiveStreamSettings(session.id, {
        settings: {
          ...session.settings,
          ...selectedTheme.config.textFontFamily,
          ...selectedTheme.config.images,
          ...selectedTheme.config.headingFontFamily,
        },
        brandColours: selectedTheme.config.colors
      });
    }

    const payload = prepareROSPayload(
      {
        id: templateId,
        title: rosTitle || 'Untitled presentation',
        createdBy: template?.created_by || auth.id,
        isEditable,
        layoutMode
      },
      getParsedSlides(),
      teamContext?.oid,
      [],
      !templateId || !isEditable
    );

    const res = await createRosTemplate(customPayload || payload);
    if (res.status) {
      setSlides(res.entity.questions);
      setTemplate(res.entity);
      setRosTitle(res.entity.title);
      setIsEditable(res.entity.editable);
      setTemplateId(res.entity.id);
      pushDataLayerForEvent('update ros template', { templateId });
      if (selectedThemeId && template?.selected_theme_id !== selectedThemeId) {
        await applySelectedThemeApi(selectedThemeId, res.entity.id);
      }
      if (!customPayload) notify(res.message, 'success');
      presentation.isClean();

      if (!isEditable || (!session && !templateId) || !!customPayload) {
        router.replace(
          `/create-presentation?templateId=${res.entity.id}`,
          undefined,
          {
            shallow: true
          }
        );
      }
      // if ((session && !session.template_id) || (session && !isEditable)) {
      //   const response = await selectPresentation(res.entity);
      //   if (response.status) {
      //     setSession((prev) => ({ ...prev!, template_id: res.entity.id }));
      //   }
      // }
    } else {
      const errorMsg = 'Something went wrong!';
      notify(res.message?.template?.[0] || errorMsg, 'error');
      presentation.isDirty();
    }
    // if (res.entity) {
    //   setDisableSaveBtn(false);
    //   dispatch({ type: ActionType.UPDATE_QUESTION, questions: res.entity.questions });
    //   setShowSavedToast(true);
    // } else {
    //   setShowToast(true);
    // }

    // setLoading(false);
    // if (showModel) router.push('/presentations');

    // if (route) { router.push(route); }
    // setPreventRoute(false);

    // if (router.query?.platformType) {
    //   await updateLiveStreams(router.query?.streamId, { templateId: res.entity.id });
    //   if (route) router.push(route);
    //   else router.push({ pathname: `/${router.query?.platformType}/${router.query?.platformId}` });
    // }
    return res;
  };

  const duplicateTemplate = async () => {
    let newSlides = slides.map(({ link_id, ...rest }) => rest);
    const payload = prepareROSPayload(
      {
        id: undefined,
        title: rosTitle || 'Untitled presentation',
        createdBy: auth.id,
        isEditable
      },
      newSlides,
      teamContext?.oid,
      [],
      true
    );
    const res = await saveTemplate(payload);
    if (res.status) selectPresentation(res.entity);
    notify('Presentation successfully duplicated.');
  };

  const deleteTemplate = async () => {
    // setLoading(true);
    if (!isEditable) return notify('This template cannot be deleted.', 'error');
    const deletedTemp = await deleteRosTemplate(templateId);
    if (deletedTemp.status) {
      notify(deletedTemp.message);
      router.push('/presentations');
    }
  };

  const addNewSlide = (
    interactionType: InteractionType,
    slide?: Interaction
  ) => {
    let options: InteractionOptions[] = [];
    options = getDefaultOptions(interactionType);
    if (slide) {
      slide.setting = { ...getGlobalSettings(interactionType), ...(slide.setting || {}) };
      slide.color = { ...getGlobalColors(interactionType), ...(slide.color || {}) };
      if (['pollWithOptions', 'wheel', 'multipleChoice'].includes(interactionType) && slide.options?.length < 2) {
        slide.options = [...(slide.options || []), ...options];
      }
    }

    updateSelectedSlideIndex(selectedSlideIndex + 1);
    setSlides((prev) => {
      const changedSlide = [...prev];
      const updatedSlide = slide || {
        ...defaultInteractionConfig,
        title: getDefaultTitle(interactionType),
        setting: getGlobalSettings(interactionType),
        color: getGlobalColors(interactionType),
        type: interactionType,
        options,

      };
      changedSlide.splice(selectedSlideIndex + 1, 0, updatedSlide);
      return changedSlide;
    });
    setSelectedPanelBtn(slide ? '' : panelBtnEnum.content);
    presentation.isDirty();
  };

  const deleteSlide = (index: number) => {
    if (slides.length === 1) return notify('Cannot delete all the slides. Please keep at least one slide.', 'error');
    slideHistory.remove(index);
    if (slides[index]?.id && isEditable) {
      deletedSlides.current.push(slides[index]?.id || '');
    }
    setSlides((prev) => prev.filter((_, i) => index !== i));
    let newIndex: number;
    if (slides.length === 1) newIndex = -1;
    else newIndex = Math.max(0, index - 1);
    setSelectedSlideIndex(newIndex);
    setSelectedPanelBtn(slides[newIndex].type === 'PDF Slide' ? '' : panelBtnEnum.content);
    presentation.isDirty();
  };

  const duplicateSlide = (index: number) => {
    setSlides((prev) => {
      const itemToDuplicate = cloneDeep(prev[index]);
      delete itemToDuplicate.id;
      delete itemToDuplicate?.link_id;
      delete itemToDuplicate?.original_question_id;
      return [
        ...slides.slice(0, index + 1),
        itemToDuplicate,
        ...prev.slice(index + 1)
      ];
    });
    setSelectedSlideIndex(index + 1);
    setSelectedPanelBtn(slides[index].type === 'PDF Slide' ? '' : panelBtnEnum.content);
    presentation.isDirty();
  };

  const onCopyInteractionLink = (index: number) => {
    const question = slides[index];
    if (question.original_question_id) {
      setSlides((prev) => {
        const changedSlide = [...prev];
        changedSlide[selectedSlideIndex].original_question_id = null;
        return changedSlide;
      });
    }
    const link_id = question.link_id;
    if (!link_id) return notify('Link not found.', 'error');
    const fullPath = `${process.env.NEXT_PUBLIC_MAIN_APP_URL}/l1/${link_id}`;
    navigator.clipboard.writeText(fullPath);
    notify('Link copied to clipboard.');
  };

  /**
   * When you change the interaction type from the content panel
   */
  const changeInteraction = (value: string | number) => {
    setSlides((prev) => {
      let slide: Interaction = {
        ...defaultInteractionConfig,
        title: getDefaultTitle(value as InteractionType),
        setting: getGlobalSettings(value as InteractionType),
        color: getGlobalColors(value as InteractionType),
      };

      slide.options = getDefaultOptions(value as InteractionType);

      const slideExists = slideHistory.sync(
        selectedSlideIndex,
        prev[selectedSlideIndex],
        value as InteractionType
      );
      if (slideExists) slide = { ...slideExists };

      const changedSlide = [...prev];
      changedSlide[selectedSlideIndex] = {
        ...slide,
        id: prev[selectedSlideIndex].id,
        type: value as InteractionType
      };
      return changedSlide;
    });
    presentation.isDirty();
  };

  /**
   * When you switch slides from the sidebar
   * @param {number} index Slide index
   */
  const switchSlide = (slide: Interaction, index: number) => () => {
    setSelectedSlideIndex(index);
    if (slide.type === 'PDF Slide') setSelectedPanelBtn('');
    else setSelectedPanelBtn(panelBtnEnum.content);
  };

  const updateOptions = (
    index?: number,
    e?: React.ChangeEvent<HTMLInputElement>,
    choiceCircleOptions: InteractionOptions[] = []
  ) => {
    setSlides((prev) => {
      if (selectedSlideIndex === null) return prev;
      const changedSlide = [...prev];
      if (typeof index === 'number' && e) changedSlide[selectedSlideIndex].options[index].body = e.target.value;
      else changedSlide[selectedSlideIndex].options = choiceCircleOptions;

      return changedSlide;
    });
    presentation.isDirty();
  };

  const updateOptionsByName = (
    index?: number,
    e?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSlides((prev) => {
      if (selectedSlideIndex === null) return prev;
      const changedSlides = [...prev];

      if (typeof index === 'number' && e) {
        const optionToUpdate = cloneDeep(changedSlides[selectedSlideIndex]?.options[index]);
        if (!optionToUpdate) return changedSlides;
        const key = e.target.name as keyof InteractionOptions;
        if (optionToUpdate && key) {
          optionToUpdate[key] = e.target.value as never;
        }

        selectedSlide.options = [
          ...selectedSlide.options.slice(0, index),
          optionToUpdate,
          ...selectedSlide.options.slice(index + 1),
        ];

        changedSlides[selectedSlideIndex] = selectedSlide;
      }

      return changedSlides;
    });
    presentation.isDirty();
  };

  const addOption = () => {
    setSlides((prev) => {
      if (selectedSlideIndex === null) return prev;
      const changedSlide = [...prev];
      changedSlide[selectedSlideIndex].options.push({
        id: v4(),
        body: ''
      });
      return changedSlide;
    });
    presentation.isDirty();
  };

  const deleteOption = (index: number) => {
    setSlides((prev) => {
      if (selectedSlideIndex === null) return prev;
      const changedSlide = [...prev];
      changedSlide[selectedSlideIndex].options.splice(index, 1);
      return [...changedSlide];
    });
    presentation.isDirty();
  };

  // question title
  const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSlides((prev) => {
      const changedSlide = [...prev];
      changedSlide[selectedSlideIndex].title = value;
      return changedSlide;
    });
    presentation.isDirty();
  };

  const updateSelectedSlideIndex = (index: number) =>
    setSelectedSlideIndex(index);

  const updateSetting = (
    key: keyof InteractionSettings,
    value: any
  ) => {
    setSlides((prev) => {
      const changedSlide = [...prev];
      let data: any;
      switch (key) {
        case 'DisplayStyle': {
          data = !value ? 'noOfVotes' : 'percentage';
          break;
        }
        case 'transientThoughtDisplayStyle': {
          data = value ? 'bubbles' : 'cloud';
          break;
        }
        case 'OptionsList': {
          data = !value ? 'character' : 'number';
          break;
        }
        case 'CombineWords':
        case 'CommentsOverlay':
        case 'FontStyle':
        case 'ProfanityFilter':
        case 'SplitWords':
        case 'Vertical':
        case 'oneUoneV':
        case 'showLatestCommentsFirst':
        case 'showUserName':
        case 'ttPlaybackSpeed':
        case 'wheelShowUser':
        case 'DefaultView':
        case 'transientThoughtShowUsername':
        case 'LinkType':
        default:
          data = value;
      }
      changedSlide[selectedSlideIndex] = {
        ...prev[selectedSlideIndex],
        setting: {
          ...prev[selectedSlideIndex].setting,
          [key]: data
        }
      };
      return changedSlide;
    });
    presentation.isDirty();
  };

  const updateColor = (
    key: keyof InteractionColors,
    value: string | boolean
  ) => {
    setSlides((prev) => {
      const changedSlide = [...prev];
      changedSlide[selectedSlideIndex] = {
        ...prev[selectedSlideIndex],
        color: {
          ...prev[selectedSlideIndex].color,
          [key]: value
        }
      };
      return changedSlide;
    });
    presentation.isDirty();
  };

  const updateTheme = (theme: Theme) => {
    setSelectedThemeId(theme.id);
    setSlides((prev) => prev.map(slide => {
      if (slide.type === 'PDF Slide') return slide;
      const newSlide = { ...slide };
      const colors = theme.config.colors;

      let FontStyle = slide.setting.FontStyle;
      if (slide.type === 'pollWithOptions') {
        FontStyle = theme.config.textFontFamily.pollWithOptionsFontStyle;
        newSlide.setting.pollWithOptionsImageSrc = theme.config.images.pollWithOptionsImageSrc;
        newSlide.setting.pollWithOptionsImageOpacity = theme.config.images.pollWithOptionsImageOpacity;
      }
      if (slide.type === 'freeForm') {
        FontStyle = theme.config.textFontFamily.pollFontStyle;
        newSlide.setting.pollImageSrc = theme.config.images.pollImageSrc;
        newSlide.setting.pollImageOpacity = theme.config.images.pollImageOpacity;
      }
      if (slide.type === 'fantasticFans') {
        FontStyle = theme.config.textFontFamily.fantasticFansFontStyle;
        newSlide.setting.ffImageSrc = theme.config.images.ffImageSrc;
        newSlide.setting.ffImageOpacity = theme.config.images.ffImageOpacity;
      }
      if (slide.type === 'textTrack') {
        FontStyle = theme.config.textFontFamily.textTrackFontStyle;
        newSlide.setting.textTrackImageSrc = theme.config.images.textTrackImageSrc;
        newSlide.setting.textTrackImageOpacity = theme.config.images.textTrackImageOpacity;
      }
      if (slide.type === 'transientThought') {
        FontStyle = theme.config.textFontFamily.transientThoughtFontStyle;
        newSlide.setting.ttImageSrc = theme.config.images.ttImageSrc;
        newSlide.setting.ttImageOpacity = theme.config.images.ttImageOpacity;
      }
      if (slide.type === 'wordCloud') {
        FontStyle = theme.config.textFontFamily.wordCloudFontStyle;
        newSlide.setting.wcImageSrc = theme.config.images.wcImageSrc;
        newSlide.setting.wcImageOpacity = theme.config.images.wcImageOpacity;
      }
      if (slide.type === 'emojisEverywhere') {
        newSlide.setting.emojisEverywhereImageSrc = theme.config.images.emojisEverywhereImageSrc;
        newSlide.setting.emojisEverywhereImageOpacity = theme.config.images.emojisEverywhereImageOpacity;
      }
      if (slide.type === 'map') {
        newSlide.setting.mapImageSrc = theme.config.images.mapImageSrc;
        newSlide.setting.mapImageOpacity = theme.config.images.mapImageOpacity;
      }
      if (slide.type === 'wheel') {
        newSlide.setting.choiceCircleImageSrc = theme.config.images.choiceCircleImageSrc;
        newSlide.setting.choiceCircleImageOpacity = theme.config.images.choiceCircleImageOpacity;
      }
      if (slide.type === 'talkingTiles') {
        newSlide.setting.talkingTilesImageSrc = theme.config.images.talkingTilesImageSrc;
        newSlide.setting.talkingTilesImageOpacity = theme.config.images.talkingTilesImageOpacity;
      }
      if (slide.type === 'newWheel') {
        newSlide.setting.winningWheelImageSrc = theme.config.images.winningWheelImageSrc;
        newSlide.setting.winningWheelImageOpacity = theme.config.images.winningWheelImageOpacity;
      }
      if (slide.type === 'linkLibrary') {
        newSlide.setting.linkLibraryImageSrc = theme.config.images.linkLibraryImageSrc;
        newSlide.setting.linkLibraryImageOpacity = theme.config.images.linkLibraryImageOpacity;
      }
      if (slide.type === 'Quick Questions') {
        newSlide.setting.quickQuestionImageSrc = theme.config.images.quickQuestionImageSrc;
        newSlide.setting.quickQuestionImageOpacity = theme.config.images.quickQuestionImageOpacity;
      }
      if (slide.type === 'ratingPolls') {
        newSlide.setting.ratingPollsImageSrc = theme.config.images.ratingPollsImageSrc;
        newSlide.setting.ratingPollsImageOpacity = theme.config.images.ratingPollsImageOpacity;
      }
      if (slide.type === 'multipleChoice') {
        FontStyle = theme.config.textFontFamily.multipleChoiceFontStyle || 'Sans-Serif';
        if (!('multipleChoiceImageSrc' in theme.config.images)) {
          newSlide.setting.HeadingFont = theme.config.default.headingFontFamily;
          newSlide.setting.multipleChoiceImageSrc = theme.config.default.imageSrc;
          newSlide.setting.multipleChoiceImageOpacity = theme.config.default.imageOpacity;
          colors.multipleChoiceBgColor = theme.config.default.bgColor;
          colors.multipleChoiceHeadingColor = theme.config.default.headingColor;
          colors.multipleChoiceChartColor = '#F97B5F';
          colors.multipleChoiceLabelColor = '#FFFFFF';
        } else {
          newSlide.setting.multipleChoiceImageSrc = theme.config.images.multipleChoiceImageSrc;
          newSlide.setting.multipleChoiceImageOpacity = theme.config.images.multipleChoiceImageOpacity;
        }
      }

      return {
        ...newSlide,
        color: colors,
        setting: {
          ...newSlide.setting,
          FontStyle
        }
      };
    }));
    presentation.isDirty();
  };

  const resetDesign = () => {
    setSlides((prev) => {
      const changedSlide = [...prev];
      const color: InteractionColors = {
        ...defaultInteractionConfig.color,
        ...getGlobalColors(changedSlide[selectedSlideIndex].type)
      };
      const fontStyle = getGlobalSettings(changedSlide[selectedSlideIndex].type).FontStyle;
      changedSlide[selectedSlideIndex].color = color;
      changedSlide[selectedSlideIndex].setting.FontStyle = fontStyle;
      return changedSlide;
    });
    presentation.isDirty();
    notify(
      'Your design changes on this slide have been undone and set back to default.'
    );
  };

  const updateLayoutMode = (mode: 'square' | 'rectangle') => {
    setLayoutMode(mode);
    presentation.isDirty();
  };

  const slideRef = useRef(slides);
  slideRef.current = slides;

  const handleDnD = (oldIndex: number, newIndex: number) => {
    const array = [...slideRef.current];
    const item = array[oldIndex];
    array.splice(oldIndex, 1);
    array.splice(newIndex, 0, item);
    setSlides(array);
    setSelectedSlideIndex(newIndex);
    setSelectedPanelBtn(slideRef.current[oldIndex].type === 'PDF Slide' ? '' : 'Content');
    slideRef.current = array;
    presentation.isDirty();
  };
  const onOptionChange = (options: InteractionOptions[]) => {
    setSlides((prev) => {
      const changedSlide = [...prev];
      changedSlide[selectedSlideIndex].options = options;
      return changedSlide;
    });
  };

  const applyToSimilarSlides = () => {
    const currentSlide = slides[selectedSlideIndex];
    setSlides((prev) => {
      return prev.map((slide) => {
        if (slide.type === currentSlide.type) {
          slide.color = { ...currentSlide.color };
          slide.setting.FontStyle = currentSlide.setting.FontStyle;
        }
        return slide;
      });
    });

    const interactionNameMap: Record<InteractionType, string> = {
      'PDF Slide': 'PDF Slide',
      wheel: 'choice circle',
      pollWithOptions: 'power polls',
      freeForm: 'open-ended polls',
      wordCloud: 'wonder words',
      map: 'magic maps',
      emojisEverywhere: 'emojis everywhere',
      talkingTiles: 'talking tiles',
      transientThought: 'transient thoughts',
      'Quick Questions': 'quick questions',
      linkLibrary: 'link library',
      textTrack: 'text track',
      newWheel: 'winning wheel',
      ratingPolls: 'rating polls',
      fantasticFans: 'fantastic fans',
      multipleChoice: 'multiple choice'
    };
    notify(
      `Your design changes have been successfully applied to all ${interactionNameMap[currentSlide.type]
      }.`
    );
  };

  const handler: TSlideHandlers = {
    applyToSimilarSlides,
    onOptionChange,
    onTitleChange: (value) => updateTitle({ target: { value } } as any),
    updateTitle,
    updateSetting,
    updateColor,
    addOption,
    updateOptions,
    deleteOption,
    resetDesign,
    updateOptionsByName

  };
  const [showGenerateWithAIModal, setShowGenerateWithAIModal] = useState(false);

  const selectedSlide = { ...slides[selectedSlideIndex], handler };

  return (
    <RoSPageContext.Provider
      value={{
        selectedTheme,
        selectedThemeId,
        isLoadingTemplates,
        showGenerateWithAIModal,
        setShowGenerateWithAIModal,
        saveState,
        templateId,
        selectedPanelBtn,
        toggleSelectedPanelBtn,
        view,
        toggleView,
        slides,
        setSlides,
        selectedSlideIndex,
        updateSelectedSlideIndex,
        switchSlide,
        addNewSlide,
        deleteSlide,
        duplicateSlide,
        changeInteraction,
        selectedSlide,
        mergeOrReplaceTemplate,
        rosTitle,
        layoutMode,
        updateLayoutMode,
        updateRoSTitle,
        selectPresentation,
        unLinkPresentation,
        saveTemplate,
        duplicateTemplate,
        deleteTemplate,
        session,
        updateTheme,
        handleDnD,
        onCopyInteractionLink
      }}
    >
      {children}
    </RoSPageContext.Provider>
  );
}

export const useRoSContext = () => {
  const context = React.useContext(RoSPageContext);
  // if (context)
  return context;
  // throw new Error(
  //   'useRoSContext can only be used within an RoSContextProvider'
  // );
};

export default RoSContext;
